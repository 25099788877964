<script setup>
import { ref, onUnmounted } from "vue";

import CastoriaLogo from "@/assets/images/castoria.svg";

const res = useState();
const countdown = ref(10);
let interval = null;

const startCountdown = (redirectUrl) => {
  if (interval) clearInterval(interval); // Clear any existing interval to avoid multiple timers.

  countdown.value = 10; // Reset the countdown to 10 seconds
  interval = setInterval(async () => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(interval); // Stop the countdown at 0
      await navigateTo(redirectUrl, { external: true });
    }
  }, 1000); // Update every second
};

// Fetch the tenant data only when the component is mounted (client-side)
onBeforeMount(async () => {
  const data = await $fetch("/api/tenant/get/", {
    method: "POST",
  });

  if (data) {
    if (data.homepage_url === "") {
      data.homepage_url = "https://iak.id"
    }
    res.value = data

    startCountdown(data.homepage_url);
  }
});

// Clean up interval on component unmount
onUnmounted(() => {
  if (interval) {
    clearInterval(interval); // Clean up the interval when the component is destroyed
  }
});
</script>

<template>
  <main class="w-full h-screen overflow-hidden">
    <div v-if="res != null" class="flex flex-col justify-start p-8 gap-24 max-w-screen-md mx-auto overflow-hidden">
      <div class="md:mt-24">
        <img v-if="res.logo_url" :src="res.logo_url" class="w-[200px] mb-4 object-contain" />
        <h1 class="text-3xl md:text-4xl text-primary mb-5 font-semibold">
          Selamat datang di halaman pembayaran {{ res.name }}!
        </h1>
        <p class="md:text-xl leading-normal mb-8">
          Anda akan diarahkan kembali ke halaman utama {{ res.name }} dalam <span class="text-red-700">{{ countdown }} detik</span>, <br />
          atau
          <a :href="res.homepage_url" class="underline text-sky-900 hover:text-sky-700 transition">tekan disini</a>
          untuk kembali secara langsung.
        </p>
        <div class="float-right flex items-center gap-2 opacity-50">
          <span>Powered by</span>
          <CastoriaLogo class="h-7" :fontControlled="false" />
        </div>
      </div>
      <div class="">
        <img src="@/assets/images/pay-illust.png" class="w-full object-contain" />
      </div>
    </div>
  </main>
</template>

<style scoped>
main {
  background-color: #ffffff;
  background-image:
    linear-gradient(180deg, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%),
    url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23141f47' fill-opacity='0.075' fill-rule='evenodd'/%3E%3C/svg%3E");
}
</style>
